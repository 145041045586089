import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "AuthView" */ '../views/AuthView.vue'),
    meta: {
        requiresAuth: false
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import( /* webpackChunkName: "Dashboard" */ '../views/DashboardView.vue'),
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/builder',
    name: 'Builder',
    component: () => import( /* webpackChunkName: "FormBuilder" */ '../views/FieldBuilder.vue'),
    meta: {
        requiresAuth: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: "404",
    component: () => import('../views/site/404View.vue'),
    meta: {
      title: "404 Not Found"
    }
  },
]

export default routes
