<template>
  <div>
    <h1>Vue.js 3 + Firebase Authentication</h1>
    <router-view />
  </div>
</template>

<script lang="js">
import {
    useAuthStore
} from '@/store/auth.ts'

export default {
  name: 'app',
  data() {
    return {
      authStore: useAuthStore(),
      isLogin: false
    }
  },
  created() {
    this.login = this.authStore.getToken !== null
  },
  mounted() {
    this.login = this.authStore.getToken !== null;

    // on change state of login in store.
    this.authStore.$subscribe((mutation, state) => {
      if (state.token !== null) {
       this.$router.push('/dashboard')
      }
    })
  },
  watch: {
    $route(to, from) {
      // if route is / and user is logged in, redirect to dashboard.
      if (to.path === '/' && this.authStore.getToken !== null) {
        this.$router.push('/dashboard')
      }
      // if path is dashboard or builder but user is not logged in, redirect to login.
      if ((to.path === '/dashboard' || to.path === '/builder') && this.authStore.getToken === null) {
        this.$router.push('/')
      }
    }
  }
}
</script>
